import { LoggedInHeader } from './LoggedInHeader';
import { LoggedOutHeader } from './LoggedOutHeader';
import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useUser from '@/hooks/useUser';

export const HEADER_HEIGHT_CLASS = 'h-[84px] md:h-[5rem]';

export const AppHeader = () => {
  const { user, isLoading } = useUser();
  const router = useRouter();

  if (isLoading) {
    return <Header />;
  } 

  // TODO: Use the Layout pattern at the page level to replace the path check logic here
  const shouldDisplayLoggedInHeader = user?.isLoggedIn && router.pathname !== '/create-account';

  return shouldDisplayLoggedInHeader ? <LoggedInHeader /> : <LoggedOutHeader />;
};

export const Header: React.FC = ({ children }) => {
  const router = useRouter();

  return (
    <>
      <header
        className={classNames(
          'fixed z-50 w-screen shadow-lg bg-suma',
          HEADER_HEIGHT_CLASS,
          {
            'z-[9999]': router.pathname === '/sumaversity',
          }
        )}
      >
        {children}
      </header>
      {/**
       * This is a trick to shift page content down by the same amount as the fixed header.
       * Height must match header height.
       */}
      <div className={HEADER_HEIGHT_CLASS}></div>
    </>
  );
};
