// import * as amplitude from '@amplitude/analytics-browser';
import { trpc } from '@/utils/trpc';

export function useNavbarDashboard() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const useNavbarDashboardTrack = () => {
    track({
      event_type: 'Navbar Dashboard',
      event_data: { screen: 'Navbar Dashboard' },
    });
    // amplitude.track('Navbar Dashboard');
  };
  return {
    useNavbarDashboardTrack,
  };
}
