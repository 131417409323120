import Link from 'next/link';
import Typography from '@/components/atoms/Typography';

interface HeaderTextLinkProps {
  href: string;
  children: React.ReactNode;
}

export const HeaderTextLink = ({ href, children }: HeaderTextLinkProps) => (
  <Link href={href}>
    <a className="py-0 select-none">
      <Typography variant="label">{children}</Typography>
    </a>
  </Link>
);
