import { FirebaseProvider } from "@/context/FirebaseContext";
import React from "react";
import { Header } from "./Header";
import SumaversiryHeader from "./sumaversity/HeaderSumaversity";

interface LoggedInHeaderProps {}

export const LoggedInHeader: React.FC<LoggedInHeaderProps> = React.memo(() => {
  return (
    <Header>
      <FirebaseProvider>
        <SumaversiryHeader />
      </FirebaseProvider>
    </Header>
  );
});

LoggedInHeader.displayName = "LoggedInHeader";
